body {
  background-color: black;
  color: white;
  margin: 0;
  bottom: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pad-top {
  padding-top: 50px;
}

.pad-top-small {
  padding-top: 10px;
}

.header-text {
  font-size: 3em;
}

.header-text-smaller {
  font-size: 2.5em;
}

.subheader-text {
  font-size: 20px;
}

.center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.pull-left {
  float: left;
}

.pull-right {
  float: left;
}

.clear {
  clear:both;
}

.box {
  border: solid 1px green;
}

.footer {
  border: solid 1px green;
  bottom: 0px;
  position: absolute;
  text-align: center;
  flex: auto;
}


